











import { Component, Vue } from 'vue-property-decorator'

import EventSpeakerForm from '@/partials/forms/Events/SpeakerForm.vue'

@Component({
  components: {
    EventSpeakerForm
  }
})
export default class EventSpeakerCreate extends Vue {

}
