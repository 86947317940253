











import { Component, Mixins } from 'vue-property-decorator'

import EventScheduleForm from '@/partials/forms/Events/ScheduleForm.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    EventScheduleForm
  }
})
export default class EventActivityEdit extends Mixins(PermissionsMixin) {
}
